jQuery(function ($) {
    $("#header nav li.menu-item-has-children").click(function (e) {
        if (!$(this).hasClass("open") && $(window).width() < 1140) {
            e.preventDefault();
            $(this).parent().find(".open ul").slideUp();
            $(this).parent().find(".open").removeClass("open");
            $(this).addClass("open").find("ul").slideDown();
        } else if ($(this).hasClass("open") && $(window).width() < 1140) {
            $(this).removeClass("open").find("ul").slideUp();
        }
    });

    $("#header #menu-toggle").click(function () {
        $(this).toggleClass("open");
        $("#header nav .open .sub-menu").slideUp();
        $("#header nav .open").removeClass("open");
        $("#header nav").stop().slideToggle();
    });

    $(".woocommerce-MyAccount-navigation li.is-active").click(function (e) {
        if ($("li.is-active").is(e.target)) {
            $(this).parent().toggleClass("open");
        }
    });

    var navWidth = $(".primary-menu").width();
    var navWidth = navWidth + 110;
    $("ul span.underline").each(function () {
        $(this).css("width", navWidth + "px");
    });

    var iFrameDOM = $("iframe.CHECKFRONT_DROPLET").contents();
    iFrameDOM.find("h2").css("font-family", "brother-1816, sans-serif");
});
